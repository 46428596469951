import React from 'react';
import Schedule from '../../sections/Product/components/common/Schedule';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import { backgroundVerificationFaq } from '../../sections/Product/BackgroundVerification/constant';
import Resources from '../../sections/Product/BackgroundVerification/Resources';
import VerificationCosts from '../../sections/Product/BackgroundVerification/VerificationCosts';
import UseCase from '../../sections/Product/BackgroundVerification/UseCase';
import Advantages from '../../sections/Product/BackgroundVerification/Advantages';
import Solutions from '../../sections/Product/BackgroundVerification/Solutions';
import HeroSection from '../../sections/Product/BackgroundVerification/HeroSection';
import MetaTagsProvider from '../../components/MetaTagsProvider';
import RootLayout from '../../layouts/RootLayout';

function BackgroundCheck() {
    return (
        <>
            <MetaTagsProvider
                title="Authenticate: Comprehensive Background Checks | Instant & Reliable Background Checks for Fraud Prevention "
                description="Secure your business with Authenticate's comprehensive background checks. Instantly verify identities, prevent fraud, and ensure compliance across all industries with our advanced screening solutions."
                path="products/background-check"
                ogImage="https://cdn.authenticating.com/public/website/OG-BG-CHECK.jpg"
                ogTitle="Authenticate: Comprehensive Background Checks for all types of businesses"
                ogDescription="Protect your business with instant background checks and global risk screening. Ensure security and compliance in any industry with Authenticate's advanced solutions."
                twitterTitle="Authenticate: Instant Background Checks for All Industries"
                twitterDescription="Automate verification, prevent fraud, and secure your business with Authenticate's comprehensive background checks in just 30 seconds."
            />
            <RootLayout getStartedCtaLink="https://portal.authenticate.com/register?utm_source=products&utm_medium=bg_check&utm_campaign=navbar">
                <HeroSection />
                <Solutions />
                <Advantages />
                <UseCase />
                <VerificationCosts />
                <Resources />
                <ProductFaq
                    faqData={backgroundVerificationFaq}
                    subHeading="Everything you need to know about Background Verifications."
                />
                <Schedule getStartedCtaLink="https://portal.authenticate.com/register?utm_source=products&utm_medium=bg_check&utm_campaign=footer" />
            </RootLayout>
        </>
    );
}

export default BackgroundCheck;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import styled, { css } from 'styled-components';
import Accordion from '../Accordion';

gsap.registerPlugin(ScrollTrigger);

const Container = styled.section`
    background: #fff;
    padding-top: 110px;

    @media screen and (max-width: 850px) {
        padding-top: 72px;
    }

    @media screen and (max-width: 600px) {
        padding-top: 54px;
    }
`;

const FaqContainer = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 100px;

    @media screen and (max-width: 850px) {
        gap: 72px;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        padding: 0 16px;
        gap: 32px;
    }
`;

const Heading = styled.h1`
    color: ${({ color }) => color || '#03045e'};
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    @media screen and (max-width: 600px) {
        font-size: 24px;
        line-height: 28.44px;
        text-align: center;
        padding: 0 10px;
        max-width: 70%;
        margin: auto;
    }

    ${({ reauthenticate }) =>
        reauthenticate &&
        css`
            font-size: 42px;
        `};
`;

const QuestionsLists = styled.div`
    flex: 2 2;
`;

const HeadingWrapper = styled.div`
    flex: 1 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SubHeading = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #6b7280;
    margin-top: 20px;

    @media screen and (max-width: 850px) {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
    }

    @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 16.59px;
        text-align: center;
        padding-top: 18px;
    }
`;

function ProductFaq({ faqData, titleColor, subHeading, reauthenticate, animation = false }) {
    useEffect(() => {
        if (animation) {
            gsap.fromTo(
                '.faq-container',
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.3,
                    ease: 'none',
                    scrollTrigger: { trigger: '.faq-container', start: 'top 80%' },
                }
            );
        }

        return () => {
            ScrollTrigger.killAll();
        };
    }, [animation]);
    return (
        <Container id="FAQ">
            <FaqContainer className="faq-container">
                <HeadingWrapper>
                    <Heading color={titleColor} reauthenticate={reauthenticate}>
                        Frequently Asked Questions
                    </Heading>
                    {subHeading && <SubHeading>{subHeading}</SubHeading>}
                </HeadingWrapper>
                <QuestionsLists>
                    {faqData?.map((question) => (
                        <Accordion question={question} key={question.id} />
                    ))}
                </QuestionsLists>
            </FaqContainer>
        </Container>
    );
}

ProductFaq.propTypes = {
    faqData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
        })
    ),
    titleColor: PropTypes.string,
    subHeading: PropTypes.string,
    reauthenticate: PropTypes.bool,
    animation: PropTypes.bool,
};

export default ProductFaq;

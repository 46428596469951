import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useCaseCardList } from './constant';
import {
    StyledChevronButton,
    StyledChevronLeft,
    StyledChevronRight,
} from '../Reauthenticate/useCases/responsiveUseCases';

const Container = styled.section`
    background: #fff;
`;

const ContentBox = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 84px 20px;
    position: relative;

    @media screen and (max-width: 800px) {
        padding: 65px 20px;
    }

    @media screen and (max-width: 480px) {
        padding: 44px 16px;
    }
`;

const GridBox = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(220px, 1fr));
    gap: 32px 20px;

    @media screen and (max-width: 750px) {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(9, 80vw);
        width: 90vw;
        gap: 20px;
        overflow-x: auto;
        margin: 0 auto;
    }
`;

const Card = styled.div`
    border: 1px solid #e8e8e8;
    padding: 24px 16px;
    border-radius: 16px;
    background-color: #fff;
`;

const Icons = styled.img`
    width: 54px;
    height: 54px;

    @media screen and (max-width: 800px) {
        width: 24px;
        height: 24px;
    }

    @media screen and (max-width: 480px) {
        width: 36px;
        height: 36px;
    }
`;

const CardHeading = styled.h3`
    font-size: 20px;
    font-weight: 500;
    line-height: 23.7px;
    color: #1d2939;

    @media screen and (max-width: 800px) {
        font-size: 14px;
        line-height: 16.59px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
        line-height: 21.33px;
    }
`;

const CardDesc = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 16px;
    color: #4b5563;

    @media screen and (max-width: 800px) {
        font-size: 12px;
        line-height: 16px;
    }

    @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 18px;
    }
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
`;

const FirstCard = styled.div`
    background: linear-gradient(131.65deg, #72c6ef -14.58%, #004e8f 178.95%);
    padding: 30px 16px;
    color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 730px) {
        order: -1;
    }
`;

const CardSymbol = styled.img`
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #fff;
    padding: 0px 18px;

    @media screen and (max-width: 1024px) {
        width: 52px;
        height: 52px;
        padding: 0 12px;
    }
`;

const Logo = styled.img`
    margin-top: 16px;
    height: 30px;
    width: auto;

    @media screen and (max-width: 1024px) {
        height: 18px;
    }

    @media screen and (max-width: 480px) {
        height: 25px;
    }
`;

const Title = styled.h3`
    font-size: 32.04px;
    font-weight: 500;
    line-height: 37.97px;
    margin-top: 12px;
    text-align: center;

    @media screen and (max-width: 1024px) {
        font-size: 14px;
        line-height: 16.59px;
    }

    @media screen and (max-width: 480px) {
        font-size: 24px;
        line-height: 28.44px;
    }
`;

const Navigation = styled(StyledChevronButton)`
    position: absolute;
    @media screen and (min-width: 480px) {
        display: none;
    }
`;

function UseCase() {
    const [isPrevDisabled, setIsPrevDisabled] = useState(true);
    const [isNextDisabled, setIsNextDisabled] = useState(false);

    const containerRef = useRef();

    useEffect(() => {
        const container = containerRef?.current;

        const handleScroll = () => {
            setIsPrevDisabled(container?.scrollLeft === 0);
            setIsNextDisabled(
                container?.scrollLeft + container?.offsetWidth >= container?.scrollWidth
            );
        };

        container?.addEventListener('scroll', handleScroll);
        return () => {
            container?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onSlideLeft = () => {
        const container = containerRef?.current;
        const cardWidth = container?.firstElementChild?.offsetWidth + 20;
        container?.scrollBy({
            left: -cardWidth,
            behavior: 'smooth',
        });
    };

    const onSlideRight = () => {
        const container = containerRef?.current;
        const cardWidth = container?.firstElementChild?.offsetWidth + 20;
        container?.scrollBy({
            left: cardWidth,
            behavior: 'smooth',
        });
    };

    return (
        <Container>
            <ContentBox>
                <Navigation disabled={isPrevDisabled} onClick={onSlideLeft}>
                    <StyledChevronLeft isDisabled={isPrevDisabled} />
                </Navigation>
                <Navigation right disabled={isNextDisabled} onClick={onSlideRight}>
                    <StyledChevronRight isDisabled={isNextDisabled} />
                </Navigation>
                <GridBox ref={containerRef}>
                    {useCaseCardList?.map((card) =>
                        card.id === 4 ? (
                            <FirstCard key={card.id}>
                                <CardSymbol src={card.icon} alt="Authenticate" />
                                <Logo src={card.title} alt="Authenticate" />
                                <Title>{card.desc}</Title>
                            </FirstCard>
                        ) : (
                            <Card key={card.id}>
                                <CardHeader>
                                    <Icons src={card.icon} alt="Card Icon" />
                                    <CardHeading>{card.title}</CardHeading>
                                </CardHeader>
                                <CardDesc>{card.desc}</CardDesc>
                            </Card>
                        )
                    )}
                </GridBox>
            </ContentBox>
        </Container>
    );
}

export default UseCase;

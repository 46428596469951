import React from 'react';
// Use case section
import file_search from '../../../images/product-assets/file_search.svg';
import globally from '../../../images/product-assets/globally.svg';
import licensing from '../../../images/product-assets/licensing.svg';
import e_commerce from '../../../images/product-assets/e-commerce.svg';
import online from '../../../images/product-assets/online.svg';
import economy from '../../../images/product-assets/economy.svg';
import lending from '../../../images/product-assets/lending.svg';
import freelance from '../../../images/product-assets/freelance.svg';
import Authenticate_light from '../../../images/logos/authenticate_light.svg';
import Authenticate_mini from '../../../images/logos/authenticate_mini.svg';
// Advantage section
import chart_icon from '../../../images/product-assets/chart_icon.svg';
import shield from '../../../images/product-assets/shield.svg';
import id_card from '../../../images/product-assets/id_card.svg';
import info_blue from '../../../images/product-assets/info_blue.svg';
import protection from '../../../images/product-assets/protection.svg';
// Solution section
import illustration_1 from '../../../images/product-assets/illustration_1.svg';
import illustration_2 from '../../../images/product-assets/illustration_2.svg';
import illustration_3 from '../../../images/product-assets/illustration_3.svg';
import illustration_4 from '../../../images/product-assets/illustration_4.svg';
import pattern_1 from '../../../images/product-assets/pattern_1.svg';
import pattern_2 from '../../../images/product-assets/pattern_2.svg';

export const useCaseCardList = [
    {
        id: 0,
        icon: file_search,
        title: 'Short-term Rental Screening',
        desc: 'Property management companies conduct trust and safety screenings on prospective short-term renters,  looking for a checkered past to avoid financial liabilities, property damage or personal harm.',
    },
    {
        id: 1,
        icon: globally,
        title: 'Volunteer Organizations',
        desc: 'Nonprofits and community groups utilize a background check to ensure the reliability and integrity of volunteers, especially when they work with vulnerable populations. This helps prevent incidents by verifying the background of individuals.',
    },
    {
        id: 2,
        icon: e_commerce,
        title: 'E-Commerce',
        desc: 'E-commerce businesses use background check companies to verify the identity of buyers and sellers, especially in transactions involving high-value goods or services. This approach helps mitigate the risk of fraud and ensures secure transactions along with regulatory compliance.',
    },
    {
        id: 3,
        icon: licensing,
        title: 'Professional Licensing',
        desc: 'By verifying a professional license, organizations, and consumers can ensure that they are working with qualified and competent individuals who meet the necessary standards and requirements in their field.',
    },
    {
        id: 4,
        icon: Authenticate_mini,
        title: Authenticate_light,
        desc: 'for every use case',
    },
    {
        id: 5,
        icon: online,
        title: 'Online Marketplaces',
        desc: 'Online marketplaces use a background check to verify seller identities and track records, aiming to prevent fraudulent listings and ensure product authenticity. This builds trust and confidence among buyers, fostering a safer business environment.',
    },
    {
        id: 6,
        icon: freelance,
        title: 'Gig Economy and Freelance Platforms',
        desc: 'Freelance and gig economy platforms conduct a background check on service providers to ensure the safety and reliability of the marketplace. This verification process is essential to maintaining a trustworthy environment where clients feel secure engaging with freelancers.',
    },
    {
        id: 7,
        icon: lending,
        title: 'Peer-to-Peer Lending Platforms',
        desc: 'Background checks are critical for assessing the trustworthiness of users on peer-to-peer exchanges. This minimizes the risk of defaults and fraud, safeguarding the assets of their owners. It is important to ensure that someone is who they say they are and that they are also not a known bad actor.',
    },
    {
        id: 8,
        icon: economy,
        title: 'Financial Services',
        desc: 'Financial institutions use background check companies in their risk assessment processes for applicants seeking loans or other financial services. These screenings help enhance financial security and trustworthiness; providing for a secure business environment.',
    },
];

export const advantageCards = [
    {
        id: 0,
        icon: chart_icon,
        title: 'Risk Reduction',
        desc: 'Minimizes potential liabilities by thoroughly verifying civil, educational, and professional histories.',
    },
    {
        id: 1,
        icon: shield,
        title: 'Fraud Prevention',
        desc: 'Detects discrepancies and fabrications in personal records, safeguarding against deceitful activities.',
    },
    {
        id: 2,
        icon: id_card,
        title: 'Regulatory Compliance',
        desc: 'Ensures adherence to industry standards and legal requirements through meticulous verification processes.',
    },
    {
        id: 3,
        icon: info_blue,
        title: 'Accuracy Assurance',
        desc: 'Provides precise and up-to-date information, enhancing decision-making confidence.',
    },
    {
        id: 4,
        icon: protection,
        title: 'Reputation Protection',
        desc: 'Maintains organizational integrity by confirming the authenticity of an individual backgrounds.',
    },
];

export const solutionCards = [
    {
        id: 0,
        img: illustration_1,
        background: pattern_1,
        title: 'US College and University Verification',
        desc: 'Authenticate® verifies education details such as school name, type, location, dates attended, degree title, current enrollment, and completion status.',
        link: {
            route: 'https://info.authenticate.com/education-verification',
            target: '_blank',
            rel: 'noreferrer',
        },
    },
    {
        id: 1,
        img: illustration_2,
        background: pattern_2,
        title: 'Employment Verification',
        desc: 'Validates company names, locations, job titles, employment dates and status, supervisor names, & contract types. ',
        link: {
            route: 'https://info.authenticate.com/employment-verification',
            target: '_blank',
            rel: 'noreferrer',
        },
    },
    {
        id: 2,
        img: illustration_3,
        background: pattern_2,
        title: 'US Professional License Verification',
        desc: 'Authenticate® verifies license titles, organizations, numbers, states, end dates, and countries to ensure accuracy and legitimacy.',
        link: {
            route: 'https://info.authenticate.com/professional-license-verification',
            target: '_blank',
            rel: 'noreferrer',
        },
    },
    {
        id: 3,
        img: illustration_4,
        background: pattern_1,
        title: 'DMV MVR Verification',
        desc: 'Authenticate® retrieves MVRs (Motor Vehicle Records) directly from DMVs (Department of Motor Vehicle), providing comprehensive license details including issuance, status, expiration, and any violations or sanctions.',
        link: {
            route: 'https://info.authenticate.com/dmv-mvr',
            target: '_blank',
            rel: 'noreferrer',
        },
    },
];

// FAQ of Background Verifications page
export const backgroundVerificationFaq = [
    {
        id: 0,
        title: 'What information is verified in a US College and University Verification?',
        desc: (
            <>
                Authenticate<sup>®</sup> verifies education details such as school name, type,
                campus, location, dates attended, degree title, current enrollment status, and
                completion status. This process ensures the authenticity of the provided educational
                background.
            </>
        ),
    },
    {
        id: 1,
        title: 'How does Authenticate® verify current or past US employment?',
        desc: (
            <>
                Authenticate<sup>®</sup> verifies employment details by checking company names,
                locations, job titles, dates of employment, current employment status, and contract
                type. The solution returns a TRUE or FALSE output confirming the authenticity of the
                provided employment information.
            </>
        ),
    },
    {
        id: 2,
        title: 'What is included in the US Professional License Verification?',
        desc: (
            <>
                Authenticate<sup>®</sup> verifies professional license details, including license
                titles, organizations, numbers, states, end dates, and countries. If data is
                unavailable, the average turnaround time for a response from the source is
                approximately 48 hours.
            </>
        ),
    },
    {
        id: 3,
        title: 'How does the DMV MVR Verification process work?',
        desc: (
            <>
                Authenticate<sup>®</sup>retrieves Motor Vehicle Records (MVRs) directly from the
                Department of Motor Vehicles (DMVs). This includes license issuance, status,
                expiration, and violations or sanctions. DMV fees may vary by state.
            </>
        ),
    },
    {
        id: 4,
        title: 'How long does it take to get results from a background verification?',
        desc: 'Most verification results are instant. More comprehensive checks, such as those involving multiple jurisdictions or international records, may take longer, depending on the availability of courts. We endeavour to provide all reports promptly while ensuring accuracy and compliance.',
    },
];

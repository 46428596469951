import React from 'react';
import styled, { css } from 'styled-components';
import Blogs from '../../../images/product-assets/auth-db-illustration.png';
import Products from '../../../images/product-assets/background-verification.gif';
import Knowledge_base from '../../../images/product-assets/knowledge-base.png';

const Container = styled.div`
    background: #fff;
`;

const ResourceContainer = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 84px 20px 0;

    @media screen and (max-width: 744px) {
        padding: 44px 20px 0;
    }
`;

const Heading = styled.h1`
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    color: #262626;

    @media screen and (max-width: 744px) {
        font-size: 32px;
        line-height: 36px;
    }

    @media screen and (max-width: 520px) {
        font-size: 24px;
        line-height: 28.44px;
    }
`;

const ResourceGrid = styled.div`
    margin-top: 54px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
`;

const Card = styled.a`
    border: 1px solid #e8e8e8;
    border-radius: 16px;
    padding: 16px;
    text-decoration: none;
    cursor: pointer;
`;

const CardHeader = styled.div`
    height: 96px;
    border-radius: 8px;

    ${({ product }) =>
        product &&
        css`
            background-color: #154997;
            height: 96px;
            padding-top: 12px;
            display: flex;
            justify-content: center;
            overflow: hidden;
        `};
`;

const CardBanner = styled.img`
    object-fit: cover;
    max-height: 96px;
    width: 100%;
    border: 1px solid #ececec;
    border-radius: 8px;

    ${({ product }) =>
        product &&
        css`
            object-fit: contain;
            width: auto;
            height: 250px;
            border: 2.18px solid #ffffff;
            border-radius: 3px;
            margin: 0 auto;
        `};
`;

const ProductTag = styled.div`
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 2px 10px;
    border-radius: 16px;
    background: ${({ background }) => background};
    color: ${({ color }) => color};
    display: inline-block;
`;

const CardHeading = styled.h3`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #262626;
    margin-top: 4px;
`;

const CardDesc = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    color: #4b5563;
    margin-top: 4px;
`;

function Resources() {
    return (
        <Container>
            <ResourceContainer>
                <Heading>Resources</Heading>
                <ResourceGrid>
                    <Card
                        href="https://authenticate.com/resources/blog/authoritative-background-checks-identity-verification"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <CardBanner src={Blogs} alt="Blogs" />

                        <ProductTag color="#B54708" background="#FFFAEB">
                            Blogs
                        </ProductTag>
                        <CardHeading>Authoritative Background Checks and ...</CardHeading>
                        <CardDesc>
                            Discover how Authenticate®'s secure and quick checks combat fraud and
                            identity theft.
                        </CardDesc>
                    </Card>
                    <Card href="/products/criminal-background-checks/">
                        <CardHeader product>
                            <CardBanner src={Products} alt="Blogs" product />
                        </CardHeader>
                        <ProductTag color="#027A48" background="#ECFDF3">
                            Product
                        </ProductTag>
                        <CardHeading>Criminal Background Verification</CardHeading>
                        <CardDesc>
                            Authenticate® provides fast, comprehensive criminal checks for enhanced
                            security.
                        </CardDesc>
                    </Card>
                    <Card
                        href="https://info.authenticate.com/background-checks"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <CardBanner src={Knowledge_base} alt="Blogs" />

                        <ProductTag color="#5925DC" background="#F4F3FF">
                            Knowledge Base
                        </ProductTag>
                        <CardHeading>Looking for answers? Ask us anything.</CardHeading>
                        <CardDesc>
                            This is your one-stop shop to learn all about Authenticate®, our
                            features, pricing, & more.
                        </CardDesc>
                    </Card>
                </ResourceGrid>
            </ResourceContainer>
        </Container>
    );
}

export default Resources;
